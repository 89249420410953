.toast-messages-container {
  position: fixed;
  z-index: 10000;
  width: 759px;
  max-width: 100%;
  top: 36px;
  left: 50%;
  transform: translate(-50%);

  .auth-container + & {
    top: 16px;
    @media (min-width: 1024px) {
      left: 80px;
      width: 320px;
      transform: translate(0);
    }
  }
}
