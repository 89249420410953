.date-filter {
  display: flex;

  label {
    font-size: 15px;
    line-height: 17px;
    color: #5F6368;
    margin-bottom: 8px;
  }

  .datepicker-group {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 240px;
    margin-left: auto;

    label {
      margin-left: auto;
      margin-right: 8px;
    }

    input[type=text] {
      border: none;
      width: 200px;
      font-size: 14px;
      line-height: 19px;
      color: #2c2c2c;
      border-bottom: 1px solid #e0e5eb;
      margin-bottom: 8px;
      border-radius: 0;
      padding: 0;
      margin-left: auto;
      
      &:focus {
        outline: none;
        border-bottom: 1px solid #2c2c2c;
      }
  
      &:hover {
        opacity: 1;
      }
    }
  
    .ui.icon.input > i.icon:before {
      top: 25%;
      left: 10px;
    }
  }
}
