.sorted-header-cell {
  .button {
    cursor: pointer;
    text-decoration: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -o-user-select: none;
    user-select: none;
    font-family: inherit !important;
    font-size: inherit !important;
    color: inherit !important;
    background: none !important;
    box-shadow: none !important;
    font-weight: inherit !important;
    padding: 0px !important;
    margin: inherit !important;
    line-height: inherit !important;
    text-align: left;

    span {
      position: relative;

      i {
        &.icon {
          position: absolute;
          right: -1rem;
          top: -0.25rem;
          opacity: 1 !important;
          margin: inherit !important;
          height: inherit !important;
        }
      }
    }
  }
}