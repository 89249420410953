@import './variables.scss';

.ui.form .field>label {
  display: inline-block;
  font-weight: $bold;
  font-size: 14px;
  line-height: 19px;
}

.ui.form .ui.checkbox {
  margin-right: 20px;
}

.validation-error {
  display: block;
}

.form-container.ui.grid {
  margin-top: 0;
  padding: 38px 0;
}

h2.header {
  margin-bottom: 22px;    
  font-size: 25px;
  line-height: 30px;
  color: #2c2c2c;
}

.ui.styled.accordion {
  width: 100%;
}

.ui.grid.form-container .row {
  padding: 0;
}

.ui.form.form-panel {
  padding: 18px 24px;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 1px 3px rgba(0,0,0,.16);
  margin-bottom: 22px;
}

.field .ui.radio.checkbox {
  display: inline-block;  
}

.daterange .clndr-calendars-segment .clndr-button-today{
  display: none;
}

.ui.form .fields .datepicker-wrapper .field .ui.input input {
  width: 215px;
}
