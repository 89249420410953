@import '../../styles/variables.scss';

.auth-container {
  display: flex;
  min-height: 100vh;
  font-size: 16px;
  line-height: 24px;
  background-color: $color-gray-0;
  animation: show 2s;
  animation-fill-mode: forwards;

  @keyframes show {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }

  .form-container {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 16px 80px;
    border-radius: 6px;
    box-sizing: border-box;

    .form {
      min-width: 320px;
      input {
        padding: 0.75em;
      }

      label {
        margin: 8px 0 8px;
      }

      .forgot-password {
        color: $color-gray-3;
        font-size: 12px;
      }

      .link-text {
        display: inline-block;
        cursor: pointer;
        color: $color-blue-0 !important;
        background: none !important;
        box-shadow: none !important;
        font-weight: inherit !important;
        padding: 0px !important;
      }

      .sign-in-container {
        margin-top: 30px;
        display: flex;
        align-items: center;
        .sign-in-button {
          width: auto;
          margin-left: auto;
          font-size: 12px;
          font-weight: 400;
          line-height: 1.42857143;
          text-transform: uppercase;
          padding: 14px 0;
          min-width: 153px;
          color: $color-gray-0;
        }
      }

      .field {
        margin: 0 0 10px 0;
      }

      .center-content {
        text-align: center;

        .separator {
          position: relative;
          margin: 24px 0;
          color: $color-gray-4;
          &:before,
          &:after {
            content: '';
            position: absolute;
            top: 11px;
            display: inline-block;
            width: 41%;
            height: 1px;
            background: $color-gray-4;
          }

          &:before {
            right: 0;
          }

          &:after {
            left: 0;
          }
        }
      }

      .error {
        padding-bottom: 16px;
        word-break: break-word;
        color: $color-red-0;
      }
    }
  }

  .form-container-footer {
    padding-top: 16px;
    font-size: 14px;
    line-height: 16px;
    color: $color-gray-2;

    .link {
      color: $color-blue-0;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }

      + .link {
        margin-left: 4px;
      }
    }
  }

  .banner {
    width: 100%;
    height: auto;
    min-height: 100vh;
    background-image: url("../../assets/singInPicture.jpg");
    background-size: cover;

    .info {
      padding: 80px 64px;
      font-size: 32px;
      line-height: 38px;
      letter-spacing: -0.48px;
      color: $color-gray-0;
    }

    h1 {
      margin: 14px 0 0;
      font-weight: $bold;
      font-size: 42px;
      line-height: 50px;
    }
  }

  @media (max-width: 1024px){
    .banner {
      display: none;
    }

    .form-container {
      width: 100%;
      align-items: center;
    }
  }

  @media (max-width: 768px){
    .form-container {
      padding: 16px;
    }
  }
}
