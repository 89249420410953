.segment.files-segment {
  margin-top: 0;
  .files-container {
    padding: 10px;
    div.column{
      min-width: 180px;
      margin: 1em 0;
    }

    .uploaded-files-list {
      max-width: 100%;
    }
  }
}
