.toggle {
  button {
    border: none;
    width: 100px;
    height: 45px;
    outline: none;
    font-weight: bold;

    &:hover {
      cursor: pointer;
    }
    
    &.readonly:hover {
      cursor: not-allowed;
    }

    &:first-child {
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;
    }

    &:last-child {
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;
    }

    &.active {
      background-color: rgb(37, 156, 244);
      color: rgb(255, 255, 255);
    }

    &.inactive {
      background-color: rgb(224, 225, 226);
      color: rgb(90, 90, 90);
    }
  }
}
