@import '../../styles/variables.scss';

.header-container {
  z-index: 1001;
  position: relative;
  display: flex;
  flex-direction: row;
  height: 54px;
  background-color: $color-gray-6;
  padding: 0 50px;

  .logoHeader-container {
    transform: scale(0.8);
    margin: 0px -30px;

    @media only screen and (max-width: 767px) {
      margin: 0px -20px;

      .logoWrapper {
        .container-projectName {
          padding-left: 10px;
          margin-left: 10px;
        }

        .projectName {
          font-size: 16px;
          line-height: 21px;
        }
      }
    }
  }

  .right {
    display: flex;
    margin-left: auto;
    flex-direction: row;
    align-items: center;
  }

  .username {
    font-size: 15px;
    line-height: 17px;
    color: $color-gray-0;
    margin: 0 20px 0 0;

  }

  .user-logo {
    height: 24px;
    width: 24px;
    background-image: url('../../assets/user.svg');
    background-repeat: no-repeat;
    margin: 0 8px;
  }

  .nav-container {
    margin-left: 24px;

    .nav-link {
      display: inline-block;
      padding: 0 16px;
      border-bottom: 3px solid $color-gray-6;
      line-height: 51px;
      font-weight: $bold;
      color: $color-gray-0;

      &.nav-link-active {
        position: relative;
        border-color: $color-blue-0;
        color: $color-blue-0;
      }
    }
  }

  .logout {
    outline: 0;
    border: 1px solid rgba($color-gray-0, .5);
    padding: 4px 9px;
    font-size: 14px;
    line-height: 17px;
    height: 25px;
    font-weight: $bold;
    color: $color-gray-0;
    background-color: transparent;
    border-radius: 3px;
    cursor: pointer;

    &:hover,
    &:focus {
      border-color: $color-gray-0;
    }
  }
}

@media only screen and (max-width: 767px) {
  .header-container {
    flex-wrap: wrap;
    justify-content: space-between;
    height: 100px;
    padding: 2px 10px;

    .nav-container {
      margin-left: 0px;
      .nav-link {
        padding: 0px 8px;
      }
    }

    .right {
      width: 100%;
    }
  }
}