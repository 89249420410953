$light: 300;
$regular: 400;
$medium: 500;
$semibold: 600;
$bold: 700;
$extrabold: 800;


$color-gray-0: #FFFFFF;
$color-gray-1: #d6d6d6;
$color-gray-2: #AAAEB3;
$color-gray-3: #828282;
$color-gray-4: #76787A;
$color-gray-5: #585a6b;
$color-gray-6: #303240;

$color-blue-0: #259cf4;

$color-red-0: #DC002E;
