.waiver-code-date {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: nowrap;

  .fields{
    min-width: 215px;
  }
  .checkbox {
    margin-left: 25px;
    text-overflow: clip;
  }
}
