@import '../../styles/variables.scss';

.users-table {
  height: calc(100vh - 136px);
  overflow-y: auto;
  flex: 1;
  font-size: 13px;
  word-break: break-word;

  .ui.table td.action-cell {
    width: 40px;
    overflow: visible;
  }

  thead {
    z-index: 10;
    position: sticky;
    top: 0;
    background: $color-gray-0;
  }

  .ui.label {
    min-width: 84px;
  }

  @media only screen and (max-width: 767px) {
    & {
      height: calc(100vh - 188px);
      padding: 0;
    }
  }
}
