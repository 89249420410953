.info-icon-container {
  align-items: baseline;
  position: relative;
  z-index: 1;
  float: right;

  &.left {
    display: inline-block;
    margin-left: 16px;
    float: none;
  }

  &.hidden {
    display: none;
  }

  p {
    box-sizing: border-box;
    width: 17px;
    height: 17px;
    border-radius: 50%;
    background-color: #d8d8d8;
    font-size: 12px;
    font-weight: 700;
    text-align: center;
    color: #fff;

    &:hover {
      background-color: #b5b5b5;
    }
  }

  .tooltip {
    position: absolute;
    left: -22px;
    min-width: 240px;
    bottom: 30px;
    padding: 16px 11px;
    background-color: #fff;
    border-top: 4px solid #259cf4;
    border-radius: 5px;
    box-shadow: 0 3px 13px 1px rgba(0, 0, 0, 0.2);
    text-align: left;
    font-size: 14px;
    font-weight: 500;
    color: #2c2c2c;
    display: none;
    white-space: pre-line;

    &::after {
      content: '';
      box-sizing: border-box;
      position: absolute;
      left: 20px;
      bottom: -10px;
      width: 0;
      height: 0;
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      border-top: 10px solid #fff;
    }
  }

  .info-icon:hover {
    cursor: pointer;

    .tooltip {
      display: block;
    }
  }
}

@media only screen and (max-width: 767px) {
  .info-icon-container {
    .info-icon .tooltip {
      min-width: 160px;
      left: auto;
      right: -15px;
      &::after {
        left: auto;
        right: 20px;
      }
    }
    &.left .info-icon .tooltip {
      left: -10px;
      &::after {
        left: inherit;
        right: inherit;
      }
    }
  }
}
