.placement-options-container {
  .disabled {
    opacity: 0.45;
  }

  .columns-container {
    margin: 0 -10px;
    display: flex;
    justify-content: space-between;
  }

  .placement-column {
    margin: 0 10px 1rem;
    border: 1px solid lightgray;
    border-radius: 5px;
    padding: 8px;
    flex: 1 1 auto;
    min-height: 250px;

    h4 {
      font-size: 14px;
      margin-left: 8px;
    }
  }

  .options-list {
    padding: 8px;
    background-color: white;
    flex-grow: 1;
    min-height: 200px;
  }

  .option {
    border: 1px solid lightgrey;
    padding: 8px;
    margin-bottom: 8px;
    border-radius: 5px;
  }

  .placement-column.available .option {
    background-color: #f5f5f5;
    color: grey;
  }

  .placement-column.final .option {
    background-color: white;
  }

  .info-message-icon .description {
    bottom: unset;
    top: 64px;
  }
  .info-message-icon .description::after {
    display: none;
  }
}

@media only screen and (max-width: 767px) {
  .columns-container {
    flex-direction: column;
  }
}
