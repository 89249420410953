@import '../../styles/variables.scss';

.datepicker-row {
  display: flex;
  flex-direction: row;
  margin-bottom: 12px;

  &:last-child {
    margin-bottom: 0;
  }


  .btn-add {
    color: #4a90e2;
    border: none;
    background-color: transparent;
    font-size: 14px;
    font-weight: $bold;
    
    &:hover {
      text-decoration: underline;
    }
  }

  .btn-remove {
    height: 38px;
    width: 38px;
    line-height: 15px;
    
    border: none;
    border-radius: 4px;
    background-color: #e8e8e8;
    
    &::before {
      display: block;
      content: "+";
      color: #979797;
      font-size: 22px;
      font-weight: $extrabold;
      transform: rotateZ(45deg);
    }
  }

  & > button {
    margin-left: 12px;
  }

  button:focus {
    outline: none;
  }

  button:hover {
    cursor: pointer;
  }
}
