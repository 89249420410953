.text-filter {
  display: flex;

  label {
    font-size: 15px;
    line-height: 17px;
    margin-right: 24px;
    color: #5F6368;
    margin-bottom: 8px;
  }

  input[type=text] {
    width: 200px;
    border: none;
    font-size: 14px;
    line-height: 19px;
    color: #2c2c2c;
    border-bottom: 1px solid #e0e5eb;
    margin-left: auto;
    margin-bottom: 8px;
    
    &:focus {
      outline: none;
      border-bottom: 1px solid #2c2c2c;
    }

    &:hover {
      opacity: 1;
    }
  }
}