@import '../../../styles/variables.scss';

.buttons-bar {
  position: sticky;
  position: -webkit-sticky; /* Safari */
  top: 0;
  padding: 12px 50px;
  margin: 0 -5px;
  box-shadow: 0 1px 6px rgba(0,0,0,.16);
  background-color: #ffffff;
  z-index: 999;

  display: flex;
  flex-direction: row;
  justify-content: flex-end;

  .divider {
    height: 30px;
    border-left: 1px solid #979797;
    margin: 3px 19px;
    opacity: .4;
  }

  button {
    margin: 0 5px;
    padding: 9px 10px;
    font-size: 15px;
    line-height: 18px;
    font-weight: $bold;
    color: #ffffff;
    background-color: $color-gray-5;
    border-radius: 3px;
    outline: 0;
    border: none;
    opacity: .85;
    cursor: pointer;

    &:hover, &:focus {
      opacity: 1;
    }

    &.submit {
      background-color: #259cf4;
    }

    &.delete {
      background-color: #db2827;
    }
  }
}

@media only screen and (max-width: 767px) {
  .buttons-bar {
    padding: 12px 10px;
    justify-content: center;
  }
}
