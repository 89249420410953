@import '../../styles/variables.scss';

.user-form {
  &.ui.form {
    position: sticky;
    top: 0;
    width: 330px;
    height: fit-content;
    margin-left: 24px;
    text-align: left;
    background: $color-gray-0;
  }

  &-footer {
    text-align: right;
  }
}
