.waivers-table {
  font-size: 13px;

  .ui.celled.grid > div.row > .column {
    padding: 1em 50px;
    .truncate-cell {
      max-height: 36px;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }

    .airports-column {
      width: 10%;
    }
    .issuing-airline-column{
      width: 7%;
    }
    .contries-column {
      width: 8.75%;
    }
    .issue-date-column {
      width: 9%;
    }
    .created-column {
      width: 8%;
    }
    .edit-column {
      width: 2.6em;
    }

    .edit-column-cell {
      text-overflow: initial;
      i.edit {
        margin: 0;
      }
    }
  }

  .state-title-cell {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
  }

  .state {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;

    .active {
      color: #259cf4;
    }

    .inactive {
      color: #8f8f8f;
    }
  }

  .date-range-container:not(:last-child) {
    border-bottom: 1px solid rgba(34, 36, 38, 0.1);
  }

  .pagination-info {
    margin-left: 32px;
    line-height: 15px;
    font-size: 13px;
  }

  .ui.pagination.menu .item {
    font-size: 13px;

    &:focus {
      outline: none;
    }

    &.active {
      color: #fff;
      background-color: #259cf4;
    }
  }

  .table tfoot.footer tr th {
    overflow: visible;
    position: sticky;
    position: -webkit-sticky;
    bottom: 0;
  }

  .show-per-page {
    margin-left: auto;
    margin-right: 8px;

    label {
      margin-right: 24px;
    }
  }

  div.pagination-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: baseline;
    >div{
      margin-bottom: 1rem;
    }
  }
}

@media only screen and (max-width: 767px) {
  .waivers-table {
    .ui.celled.grid > div.row > .column {
      padding: 1em 10px;
    }
  }
}

@media only screen and (max-width: 430px) {
  .waivers-table {
    .pagination-container {
      .pagination.menu a.item {
        font-size: 10px;
        min-width: 1em;
        padding: 0.6em 1em;
      }
    }
  }
}
