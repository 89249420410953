@import '../../styles/variables.scss';

.quick-filters-container {
  line-height: 18px;
  font-weight: $semibold;
  background-color: #fff;
  color: $color-gray-5;
  border: none;
  display: flex;
  flex-grow: 1;
  justify-content: space-between;
  .quick-filters-panel,
  .quick-filters-tooltip {
    div.filter-item {
      opacity: 0.85;
      display: inline-flex;
      margin: 3px;
      label {
        &.filter-item-label {
          cursor: pointer;
          opacity: inherit;
          &:hover,
          &:focus {
            opacity: 1;
            text-shadow: 1px 2px 5px $color-gray-1;
          }
        }
        span {
          display: block;
          padding: 5px 8px;
          border-radius: 3px;
          border: 1px solid #fff;
        }
        input {
          position: absolute;
          display: none;
          &:checked + span {
            color: $color-blue-0;
          }
          &:checked + span.active-bordered {
            border-color: $color-blue-0;
          }
        }
      }
    }
  }
}

div.full-search-container {
  margin-right: 3px;

  .full-search-item {
    margin: 3px;
  }

  .input-full-search {
    max-width: 120px;
  }
  i.icon.filter,
  i.icon.clean-icon {
    color: $color-gray-5;
    opacity: 1;
  }
  i.icon.filter {
    margin-right: 0;
  }
}
