.users-page {
  padding: 1em 50px;

  .users-title-container {
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;
    text-align: right;

   .ui.input {
     width: 330px;
     font-size: 1rem;
   }
  }

  .users-page-container {
    display: flex;
    justify-content: space-between;
  }

  @media (max-width: 1024px){
    & {
      padding: 10px;
    }

    &.form-open {
      .users-table {
        display: none;
      }

      .users-table + .user-form {
        width: 100%;
        margin-left: 0;
      }
    }

    .users-title-container {
      .ui.input {
        width: 362px;
        font-size: 1rem;
      }
    }
  }

  @media (max-width: 768px){
    .users-title-container {
      flex-direction: column;

      .ui.primary.button {
        width: max-content;
        margin-top: 16px;
      }

    }
  }
}

