@import "../../styles/variables.scss";

.waivers-list-bar {
  position: sticky;
  position: -webkit-sticky; /* Safari */
  top: 0;
  padding: 12px 50px;
  box-shadow: 0 1px 6px rgba(0, 0, 0, 0.16);
  background-color: #ffffff;
  z-index: 999;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: baseline;
  font-size: 14px;

  >div{
    margin: 0 -3px;
  }

  .waivers-list-buttons-bar {
    button {
      margin: 3px;
      padding: 9px 10px;
      line-height: 18px;
      font-weight: $bold;
      color: #ffffff;
      background-color: $color-gray-5;
      border-radius: 3px;
      outline: 0;
      border: none;
      opacity: 0.85;
      cursor: pointer;

      &:hover,
      &:focus {
        opacity: 1;
      }

      &.add {
        background-color: #259cf4;
      }
    }
  }
}

@media only screen and (max-width: 767px) {
  .waivers-list-bar {
    padding: 7px 10px;
    .waivers-list-buttons-bar {
      margin: 0 -3px;
    }
  }
}
