.ui.dimmer {
  position: fixed;
}

.ui.loader:before, .ui.loader:after {
  width: 148px;
  height: 148px;
  margin: 0 0 0 -18px;
}

.ui.loader:after {
  display: none;
}

.loader {
  position: absolute;
  top: 50%;
  left: 50%;
  margin: auto;
  transform: translate(-50%, -50%);
  width: 400px;
}

.plane {
  animation: spin 1s linear infinite;
  fill: #fff;
  transform-origin: 17% 20%;
}

@keyframes spin {
    0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
