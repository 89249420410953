@import '../../../styles/variables.scss';

.author-info {
  position: sticky;
  top: 70px;
  p {
    font-size: 14px;
    line-height: 19px;
    font-weight: $medium;
  }

  .author-info-label {
    font-weight: $bold;
  }
}