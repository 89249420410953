.checkbox-group-filter {
  display: flex;

  label {
    font-size: 15px;
    line-height: 17px;
    margin-right: 24px;
    color: #5F6368;
    margin-bottom: 8px;
  }

  .checkbox-group {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 200px;
    margin-left: auto;

    .ui.checkbox {
      display: flex;
      flex-grow: 1;
      margin-bottom: 8px;

      label {
        margin: 0;
      }
    } 
  }
}