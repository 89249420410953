@import '../../styles/variables.scss';

div.waiver-filters {
  margin-right: 3px;

  &>button,
  & .waiver-filters-menu .buttons button {
    padding: 9px 10px;
    margin: 3px;
    line-height: 18px;
    font-weight: $bold;
    color: #ffffff;
    background-color: $color-gray-5;
    border-radius: 3px;
    outline: 0;
    border: none;
    opacity: 0.85;
    cursor: pointer;

    &:hover,
    &:focus {
      opacity: 1;
    }

    &.search {
      background-color: $color-blue-0;
    }
  }

  .waiver-filters-menu {
    position: absolute;
    display: flex;
    flex-direction: column;
    background-color: #fff;
    box-shadow: 0 1px 6px rgba(0, 0, 0, 0.16);
    padding: 24px 24px 12px;
    border-radius: 8px;
    z-index: 1;

    &.hidden {
      display: none;
    }

    .button {
      &.close {
        cursor: pointer;
        position: absolute;
        right: 14px;
        top: 12px;
        opacity: 0.5;
        color: inherit !important;
        background: none !important;
        box-shadow: none !important;
        font-weight: inherit !important;
        padding: 0px !important;
        margin: 0px !important;

        &> {
          .icon {
            &:not(.button) {
              margin: 0px;
              height: auto;
              width: auto;
              opacity: 0.5;

              &:hover {
                opacity: 1;
              }
            }
          }
        }

        &:hover {
          opacity: 1;
        }
      }
    }

    .filter {
      display: flex;

      label {
        font-size: 15px;
        line-height: 17px;
        margin-right: 24px;
        color: #5f6368;
        margin-bottom: 8px;
      }

      .checkbox-group {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        width: 200px;
        margin-left: auto;

        .ui.checkbox {
          display: flex;
          flex-grow: 1;
          margin-bottom: 8px;

          label {
            margin: 0;
          }
        }
      }
    }

    .buttons {
      margin-top: 20px;
      margin-left: auto;
    }
  }
}