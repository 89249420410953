.form-container {
  padding: 38px 0;

  .fields:not(.unstackable) > .field {
    margin: 0 0 1em;
  }

  .waiver-codes-container {
    .ui.styled.accordion {
      border-radius: 0;

      &:first-child {
        border-top-left-radius: 3px;
        border-top-right-radius: 3px;
      }

      &:last-child {
        border-bottom-left-radius: 3px;
        border-bottom-right-radius: 3px;
      }
    }
  }
}
